import { graphql } from 'gatsby';
import React from 'react';

// Components
import { FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, MeetingMinutesTable } from 'templates';

// Types
import { MeetingMinute } from 'src/types';

interface Props {
  data: {
    meetingMinutes: MeetingMinute;
  };
}

const FILTERS = [{ title: 'Entry', gqlKey: 'year' }];

export default (props: Props) => {
  const { meetingMinutes } = props.data;

  return (
    <SEOContent title="All Meeting Minutes">
      <PageLayout>
        <BasicTemplate templateTitle="All Meeting Minutes">
          <FilterBar
            data={meetingMinutes}
            filters={FILTERS}
            render={tableData => <MeetingMinutesTable data={tableData} />}
          />
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export const query = graphql`
  {
    meetingMinutes: allAirtable(
      filter: { table: { eq: "Meeting Minutes" } }
      sort: { fields: data___created_date, order: ASC }
      limit: 365
    ) {
      nodes {
        data {
          created_date(formatString: "LLLL")
          link: meeting_minutes_id
          year
          meeting_minutes_id
        }
      }
    }
  }
`;
